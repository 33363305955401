/* kaoxZEN // portfolio	*/
@import "~bootstrap/dist/css/bootstrap.css";
@import "~animate.css/animate.min";

/* Basics
------------------------------ */
html {
  /* Moz */
  height: 100%;
}

/* FONTS
------------------------------ */

@font-face {
  font-family: "FuturaStdLight";
  src: url("assets/fonts/futurastd-light.eot");
  src: url("assets/fonts/futurastd-light.eot") format("embedded-opentype"),
    url("assets/fonts/futurastd-light.woff") format("woff"),
    url("assets/fonts/futurastd-light.ttf") format("truetype"),
    url("assets/fonts/futurastd-light.svg#FuturaStdLight") format("svg");
}

@font-face {
  font-family: "FuturaStdMedium";
  src: url("assets/fonts/futurastd-medium.eot");
  src: url("assets/fonts/futurastd-medium.eot") format("embedded-opentype"),
    url("assets/fonts/futurastd-medium.woff") format("woff"),
    url("assets/fonts/futurastd-medium.ttf") format("truetype"),
    url("assets/fonts/futurastd-medium.svg#FuturaStdMedium") format("svg");
}

@font-face {
  font-family: "FuturaStdMediumOblique";
  src: url("assets/fonts/futurastd-mediumoblique.eot");
  src: url("assets/fonts/futurastd-mediumoblique.eot")
      format("embedded-opentype"),
    url("assets/fonts/futurastd-mediumoblique.woff") format("woff"),
    url("assets/fonts/futurastd-mediumoblique.ttf") format("truetype"),
    url("assets/fonts/futurastd-mediumoblique.svg#FuturaStdMediumOblique")
      format("svg");
}

@font-face {
  font-family: "FuturaStdBold";
  src: url("assets/fonts/futurastd-bold.eot");
  src: url("assets/fonts/futurastd-bold.eot") format("embedded-opentype"),
    url("assets/fonts/futurastd-bold.woff") format("woff"),
    url("assets/fonts/futurastd-bold.ttf") format("truetype"),
    url("assets/fonts/futurastd-bold.svg#FuturaStdBold") format("svg");
}

@font-face {
  font-family: "FuturaStdExtraBoldOblique";
  src: url("assets/fonts/futurastd-extraboldoblique.eot");
  src: url("assets/fonts/futurastd-extraboldoblique.eot")
      format("embedded-opentype"),
    url("assets/fonts/futurastd-extraboldoblique.woff") format("woff"),
    url("assets/fonts/futurastd-extraboldoblique.ttf") format("truetype"),
    url("assets/fonts/futurastd-extraboldoblique.svg#FuturaStdExtraBoldOblique")
      format("svg");
}

@font-face {
  font-family: "FuturaBlackBTRegular";
  src: url("assets/fonts/futurak.eot");
  src: url("assets/fonts/futurak.eot") format("embedded-opentype"),
    url("assets/fonts/futurak.woff") format("woff"),
    url("assets/fonts/futurak.ttf") format("truetype"),
    url("assets/fonts/futurak.svg#FuturaBlackBTRegular") format("svg");
}

/* Colors
------------------------------ */

:root {
  --form-control-color: #00dd33;
}

/* Link Styles
------------------------------ */
a:link,
a:active,
a:visited {
  text-decoration: none;
  color: #000;
}
a:hover,
a:focus {
  text-decoration: underline;
  color: #000;
  cursor: pointer;
}

/* Main Layout
------------------------------ */
body {
  background-color: #00dd33;
  margin-left: 0px;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  background-attachment: fixed;
  // background-image: url(http://prototypes.kaox.tv/bgY.php);
  background-image: url(http://kaox.tv/bgY.php);

  font-family: "FuturaStdMedium", Courier, sans-serif;
}

h1,
h2 {
  font-family: "FuturaBlackBTRegular", "FuturaStdExtraBoldOblique", Courier,
    sans-serif;
}

.black {
  background-color: #000;
}

.color {
  background-color: limegreen;
}

.contorno {
  border: 1px solid var(--gray-80);
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// @media screen and (max-width: 991.98px) {
//   .hide-on-middle {
//     display: none !important;
//   }
// }

// @media screen and (min-width: 991.99px) {
//   .show-on-middle {
//     display: none !important;
//   }
// }

// ::-webkit-scrollbar {
//   width: 8px;
//   height: 8px;
// }
// ::-webkit-scrollbar-thumb {
//   background: var(--primary-80);
//   border-radius: 8px;
// }
// ::-webkit-scrollbar-thumb:hover {
//   background: var(--primary-70);
// }
// ::-webkit-scrollbar-track {
//   background: var(--primary-90);
//   border-radius: 8px;
//   box-shadow: inset 7px 10px 12px var(--white);
// }

#home {
  text-align: right;
  float: left;
  width: 580px;
  padding-right: 20px;
  padding-top: 0px;
  margin-top: 20px;
}
#main {
  text-align: right;
  float: left;
  width: 800px;
  padding-right: 40px;
  padding-top: 0px;
}
#logo {
  float: left;
  height: auto;
  margin-left: 0px;
  margin-top: 30px;
  position: fixed;
  z-index: 15;
}
#credit,
#sort {
  float: right;
  position: fixed;
  background-color: #000;
  z-index: 1;
  right: 0px;
  bottom: 0px;
  color: #fff;
  font-size: 0.7em;
  clear: both;
}
#credit a,
#sort a {
  text-decoration: none;
  color: #fff;
}
#credit a:hover,
#sort a:hover {
  color: #748da0;
  text-decoration: underline;
}

#gallerytitle {
  width: auto;
  height: 20px;
  text-align: left;
  float: left;
  font-size: 16px;
  /* 	font-weight: bold; */

  font-family: "FuturaStdMedium", "FuturaStdExtraBoldOblique", Courier,
    sans-serif;

  color: #fff;
}

#menu {
  width: auto;
  height: 20px;
  text-align: right;
  float: right;
  color: #fff;
  margin-right: 20px;
}

#superior {
  background-color: #000;
  height: auto;
  width: 100%;
  text-align: justify;

  z-index: 10;
  position: fixed;
  top: 0px;
  left: 0px;

  padding-right: 10px;
  padding-left: 10px;
  padding-bottom: 3px;
  padding-top: 3px;

  color: #fff;
}
#superior a {
  color: #fff;
  text-decoration: none;
}
#superior a:hover {
  text-decoration: underline;
}

#conteudo {
  background-color: #000;
  height: auto;
  width: auto;
  text-align: justify;

  z-index: 9;
  position: absolute;
  top: 20px;
  right: 0px;

  padding-right: 10px;
  padding-left: 10px;
  padding-bottom: 3px;
  padding-top: 3px;
  font-size: 14px;

  font-family: "FuturaStdLight", Courier, sans-serif;

  color: #fff;
}
#conteudo a {
  color: #fff;
  text-decoration: none;
}
#conteudo a:hover {
  text-decoration: underline;
}
#cnxs {
  float: right;
  z-index: 7;
  width: 380px;
  color: #000;
}
#cnxs a {
  color: #000;
  text-decoration: none;
}
#cnxs a:hover {
  text-decoration: line-through;
}

#search {
  background-color: #000;
  height: auto;
  width: 160px;
  text-align: justify;

  z-index: 8;
  position: fixed;
  bottom: 20px;
  left: 0px;
  padding: 0px;
  font-size: 12px;

  color: #fff;
}

#slides {
  z-index: 5;
  position: absolute;
  top: 137px;
  left: 0px;
  padding: 0px;
}

#images {
  float: left;
  text-align: right;
  // margin-top: 650px;
  margin-left: 16px;
  width: auto;
  height: 200px;
  z-index: 60;
}

/* Image Thumbnails
------------------------------ */
.imagethumb a,
.imagethumb a:hover {
  /* 	display: block; */
  background-color: #fff;
}
.imagethumb a:visited {
  background-color: #302;
}
.imagethumb a:hover {
  background-color: #c4d8d3;
}
.imagethumb {
  float: right;
}

/* Album Thumbnails // HOME
------------------------------ */
#sidebar {
  margin-top: 48px;
  /* 	width: 250px; */
  /* 	height: 600px; */
  float: left;
}

/* Album Thumbnails // HOME
------------------------------ */
#albums {
  z-index: 30;
  position: absolute;
  top: 20px;
  right: 0px;
  width: 1000px;
  height: auto;
  float: right;
}

#albumtitledesc {
  margin-top: 4px;
  margin-left: 4px;
  margin-right: 4px;
  padding: 8px;
  color: #111;
  font-size: 16px;
  width: auto;
  height: 80px;
  letter-spacing: 0px;
  text-align: left;
  background-image: url(assets/images/gradbar.gif);
  display: block;

  font-family: "FuturaStdExtraBoldOblique", Courier, sans-serif;
}

.thumb {
}

.hover {
  display: none;
}

.hover img {
  display: none;
}

.nohover {
  background-color: green;
}

.album {
  width: 250px;
  height: 250px;
  float: left;
  z-index: 66;
}

// .album div:hover{visibility: hidden;}

.albumhover {
  width: 250px;
  height: 250px;
  float: left;
  /* 	background-color:#111; */
}

.albumhover img {
  display: none;
}

.album .thumb {
  float: left;
}

.albumdesc {
  margin-top: 4px;
  margin-left: 4px;
  margin-right: 4px;
  padding: 8px;
  color: #111;
  font-size: 16px;
  width: auto;
  height: 80px;
  letter-spacing: 0px;
  text-align: left;
  background-image: url(assets/images/gradbar.gif);
  display: block;

  font-family: "FuturaStdExtraBoldOblique", Courier, sans-serif;
}
.albumdesc a {
  color: #111;
  text-decoration: none;
}
.albumdesc a:hover {
  color: #111;
  text-decoration: line-through;
}

.albumtags {
  margin-top: 2px;
  margin-left: 2px;
  color: #111;
  font-size: 12px;
  text-align: left;

  width: 250px;
  z-index: 14;

  font-family: "FuturaStdExtraBoldOblique", Courier, sans-serif;
}
.albumtags a {
  color: #111;
  text-decoration: none;
}
.albumtags a:hover {
  color: #111;
  text-decoration: line-through;
}

.desctag {
  z-index: 20;
}

.desctag ul {
  display: inline;
  list-style-type: none;
}

.desctag li {
  list-style-type: none;
  float: left;
  // background-color: #00FF66;
  color: greenyellow;

  color: #333;
}

/* Individual Image
------------------------------ */
#image {
  position: absolute;
  right: 0px;
  text-align: left;
  display: block;
  background-color: #000;
  padding: 80px;
  z-index: 50;
}

#imageDesc {
  position: absolute;
  left: 40px;
  bottom: 160px;
  width: 320px;
  text-align: left;
  display: block;
  background-color: #000;
  font-size: 11px;
  padding: 14px;
  color: #fff;
  z-index: 55;
}

/* Page Navigation
------------------------------ */
ul.pagelist {
  clear: both;
  padding: 0;
  margin: 20px 0 0 0;
  list-style-type: none;
}
ul.pagelist li {
  display: inline;
}
ul.pagelist li a,
ul.pagelist {
  padding: 4px;
}
ul.pagelist li.current a {
  font-weight: bold;
  text-decoration: none;
  cursor: default;
  color: #748da0;
}
ul.pagelist li.prev {
  margin-right: 10px;
}
ul.pagelist li.next {
  margin-left: 10px;
}
.disabledlink {
  color: #294550;
  cursor: default;
}
.pagenav {
  clear: both;
}
.disabled_nav {
  visibility: hidden;
}

/* Miscellaneous
------------------------------ */
hr {
  clear: both;
  height: 0;
  border: 0;
  border-top: 1px solid #444;
  margin: 0 0 12px 0;
  padding: 0;
}
img {
  border: 0;
}
blockquote {
  border-left: 1px dotted #96967e;
  margin-left: 20px;
  padding-left: 20px;
  color: #363630;
}

#imagemetadata {
  text-align: right;
  position: relative;
  display: none;
}

#imagemetadata table {
  text-align: left;
  line-height: 1em;
  border: 1px solid #ccc;
  position: absolute;
  top: 2em;
  right: 0px;
  background-color: #fafafa;
}

#imagemetadata table td {
  border-bottom: 1px solid #f0f0f0;
  background-color: #f8f8f8;
  padding: 2px 5px;
}

/* Tags
------------------------------ */
.tag {
  color: greenyellow;
  background-color: #111;
  margin: 4px;
  cursor: pointer;
}

#tags {
  float: left;
  position: fixed;
  bottom: 72px;
  left: 32px;
  z-index: 100;
  width: 640px;

  font-family: "FuturaStdExtraBoldOblique", Courier, sans-serif;
}
.taglist ul {
  display: inline;
  list-style-type: none;
}

.taglist li {
  list-style-type: none;
  float: left;
  // background-color: #00FF66;
  color: greenyellow;

  margin: 4px;
}

.taglist .tags_title {
  background: none;
  padding-right: 5px;
  padding-left: 0;
}

/* Tag Cloud
---------------------------- */
#tag_cloud {
  text-align: center;
  background: #111;
}

#tag_cloud ul li {
  display: block;
}

#tag_cloud p {
  padding: 10px 0;
  margin: 20px 0;
  background: #222;
  border-top: 3px solid #666;
  border-bottom: 3px solid #666;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

//NOVOX

.text-bgo {
  font-size: xx-large;
  color: greenyellow;
  float: left;
  position: absolute;
  z-index: 10;
}

.painel-debug {
  font-size: large;
  background-color: honeydew;

  margin: 100px;
  position: absolute;
  float: right;
  padding: 60px;
  z-index: 20;
}

//// FORM
///
///
///
///

.form-group {
  label {
    background-color: #000;
    color: var(--form-control-color);
    font-style: italic;
    font-size: 0.8em;
  }

  input,
  button,
  select,
  optgroup,
  textarea {
    width: 100%;

    background-color: #333;
    color: #dedede;
  }
  input,
  button,
  select,
  optgroup {
    height: 28px;
  }

  textarea {
    height: 200px;
  }

  input[type="radio"] {
    appearance: none;
    background-color: #fff;
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 1.15em;
    height: 1.15em;
    border: 0.15em solid currentColor;
    border-radius: 50%;
  }

  button {
    background-color: #555;
    font-size: 0.8em;
    color: var(--form-control-color);
  }

  margin-bottom: 6px;
}
